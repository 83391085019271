<template>
  <div>
    <HeaderPanel
      title="Booking Setting"
      :filter="filter"
      placeholder="Search Booking Name"
      @search="getList"
      :hasDropdown="false"
      :hasFilter="false"
      routerPath="/setting/booking/0"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <Table
        :filter="filter"
        :pageOptions="pageOptions"
        :fields="fields"
        :items="items"
        :isBusy="isBusy"
        :rows="rows"
        :showingTo="showingTo"
        @filterPage="filterPage"
      />
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
import HeaderPanel from "@/components/HeaderPanel";
import Table from "./Table";
import SideBarFilter from "@/components/SideBarFilter";

export default {
  name: "admin",
  components: {
    HeaderPanel,
    SideBarFilter,
    Table,
  },
  data() {
    return {
      Role: [{ name: "Shopee", id: 1 }],
      RoleSelected: [],
      RoleSelectedAll: false,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      fields: [
        {
          key: "name",
          label: "Booking Name",
        },
        {
          key: "created_time",
          label: "Create Date",
          class: "text-center",
        },
        {
          key: "updated_time",
          label: "Last Update",
        },
        { key: "actions", label: "Action" },
      ],
      items: [],
    };
  },
  created() {
    this.getList();
  },
  watch: {
    "filter.role"(newValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.RoleSelectedAll = false;
      } else if (newValue.length === this.Role.length) {
        this.RoleSelectedAll = true;
      } else {
        this.RoleSelectedAll = false;
      }
    },
  },
  methods: {
    RoleToggleAll(checked) {
      this.filter.role = checked ? this.Role.map((el) => el.id).slice() : [];
    },

    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    async getList() {
      this.isBusy = true;
      await this.$store.dispatch("getBookingList", this.filter);
      let data = this.$store.state.booking.formList;

      this.items = data.detail.booking;
      this.rows = data.detail.total;
      this.isBusy = false;
    },
    filterPage(filter) {
      this.filter = filter;
      this.getList();
    },
  },
};
</script>
